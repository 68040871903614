import { computed, inject, InjectionToken } from '@angular/core';
import { signalStore, withMethods } from '@ngrx/signals';
import { ChildStore } from 'src/app/features/children/children.store';
import { ReadingProgramDto } from '../../reading-program/models/reading-program-dto';
import {
  mapReadingCategoryProgramToDto,
  mapReadingProgramDtoToProgram,
} from '../services/reading-category-program.mapper.ts';
import { withSpecificProgram } from 'src/app/infrastructure/store-features/with-specific-program.feature';
import { ReadingCategoryProgramSettings } from '../reading-category-program.settings';
import { ReadingCategoryProgramApiServiceToken } from '../services/reading-category-program-api.service';
import { ReadingProgram } from '../../reading-program/models/reading-program';
import { RoutingStore } from 'src/app/infrastructure/routing.store';
import { ProgramStore } from 'src/app/infrastructure/models/interfaces/program-store';
import { ReadingCategoryCrudMessages } from '../models/reading-category-crud-messages';

export const ReadingCategoryProgramStoreToken = new InjectionToken<
  ProgramStore<ReadingProgram>
>('ReadingCategoryProgramStore');

export const ReadingCategoryProgramStore = signalStore(
  {
    providedIn: 'root',
  },
  withMethods((_, childStore = inject(ChildStore)) => ({
    mapEntity: (dto: ReadingProgramDto) =>
      mapReadingProgramDtoToProgram(dto, childStore.entities()),
    mapEntityToDto: (program: ReadingProgram) =>
      mapReadingCategoryProgramToDto(program),
  })),
  withSpecificProgram<ReadingProgram, ReadingProgramDto>(
    ReadingCategoryProgramApiServiceToken,
    [
      ReadingCategoryProgramSettings.singleWords,
      ReadingCategoryProgramSettings.couplets,
      ReadingCategoryProgramSettings.sentences,
    ],
    ReadingCategoryCrudMessages
  ),
  withMethods(
    (
      state,
      routerStore = inject(RoutingStore),
      childStore = inject(ChildStore)
    ) => {
      const currentProgram = computed(() => {
        const currentUrl = routerStore.currentUrl();
        const childrenUrlIdentifier =
          childStore.selectCurrentChildrenUrlIdentifier();

        if (!childrenUrlIdentifier) {
          return undefined;
        }

        if (
          currentUrl.includes(
            ReadingCategoryProgramSettings.singleWords.urlName
          )
        ) {
          return state
            .programs()
            .find(
              (x) =>
                x.programType ===
                  ReadingCategoryProgramSettings.singleWords.programType &&
                x.childrenUrlIdentifier === childrenUrlIdentifier
            );
        } else if (
          currentUrl.includes(ReadingCategoryProgramSettings.couplets.urlName)
        ) {
          return state
            .programs()
            .find(
              (x) =>
                x.programType ===
                  ReadingCategoryProgramSettings.couplets.programType &&
                x.childrenUrlIdentifier === childrenUrlIdentifier
            );
        } else if (
          currentUrl.includes(ReadingCategoryProgramSettings.sentences.urlName)
        ) {
          return state
            .programs()
            .find(
              (x) =>
                x.programType ===
                  ReadingCategoryProgramSettings.sentences.programType &&
                x.childrenUrlIdentifier === childrenUrlIdentifier
            );
        }

        return undefined;
      });

      return { currentProgram };
    }
  )
);
