import { signalStore, withHooks, withMethods } from '@ngrx/signals';
import { inject } from '@angular/core';
import { ChildStore } from 'src/app/features/children/children.store';
import { withSpecificProgram } from 'src/app/infrastructure/store-features/with-specific-program.feature';
import { AppLogger } from 'src/app/infrastructure/services/logging/app-logger.service';
import { NumbersProgram } from '../models/numbers-program';
import { NumbersProgramDto } from '../models/numbers-program.dto';
import { NumbersProgramSettings } from '../numbers-program.settings';
import {
  mapNumbersProgramDtoToNumbersProgram,
  mapNumberProgramToNumbersProgramDto,
} from '../services/numbers-program.mappers';
import { NumericProgramApiServiceToken } from '../services/numeric/numeric-program-api.service';
import { withNumbersProgramFeatures } from './with-numbers-program.feature';
import { generateCrudMessagesForProgram } from '../../all-programs/helpers/generate-crud-message';

export const NumericProgramStore = signalStore(
  { providedIn: 'root' },
  withMethods((_, childStore = inject(ChildStore)) => ({
    mapEntity: (dto: NumbersProgramDto) => {
      const program = mapNumbersProgramDtoToNumbersProgram(
        dto,
        childStore.entities()
      );
      return program;
    },
    mapEntityToDto(program: NumbersProgram) {
      return mapNumberProgramToNumbersProgramDto(program);
    },
  })),
  withSpecificProgram<NumbersProgram, NumbersProgramDto>(
    NumericProgramApiServiceToken,
    [NumbersProgramSettings.numericProgram],
    generateCrudMessagesForProgram('numeric')
  ),
  withNumbersProgramFeatures(),
  withHooks((_, logger = inject(AppLogger)) => ({
    onInit: () => {
      logger.debug('NumericProgramStore initialized');
    },
  }))
);
