import { computed, inject, InjectionToken } from '@angular/core';
import {
  patchState,
  signalStoreFeature,
  type,
  withComputed,
  withHooks,
  withMethods,
  withState,
} from '@ngrx/signals';
import { ProgramSet } from 'src/app/programs/set-programs/models/program-set';
import { PlanSetProgramService } from './services/plan-set-program.service';
import { RoutingStore } from 'src/app/infrastructure/routing.store';
import { rxMethod } from '@ngrx/signals/rxjs-interop';
import {
  concat,
  filter,
  interval,
  of,
  pipe,
  switchMap,
  take,
  takeUntil,
  takeWhile,
  tap,
  timer,
} from 'rxjs';
import { ProgramSetStore } from 'src/app/infrastructure/models/interfaces/program-set-store';
import { ProgramSetDTO } from 'src/app/programs/set-programs/models/dtos/program-set-dto';
import { AppLogger } from 'src/app/infrastructure/services/logging/app-logger.service';

export type EditSetInfoState<TSet extends ProgramSet> = {
  contentName: string;
  setName: string;
  titleName: string;
  defaultSet: TSet;
  urlNameForProgramEdit: string[];
};

export type EditSetState<TSet extends ProgramSet> = {
  set: TSet;
};

export function withEditSetFeature<
  TSet extends ProgramSet,
  TSetDto extends ProgramSetDTO
>(storeToken: InjectionToken<ProgramSetStore<TSet, TSetDto>>) {
  return signalStoreFeature(
    {
      state: type<EditSetInfoState<TSet>>(),
    },
    withState({
      set: type<TSet>(),
    } as EditSetState<TSet>),
    withComputed((_, routingStore = inject(RoutingStore)) => {
      const isEditMode = computed(() => {
        const url = routingStore.currentUrl();

        if (!url.startsWith('/plan/program/')) {
          return false;
        }

        const urlParts = url.split('/');
        return urlParts[urlParts.length - 2].startsWith('edit');
      });

      return { isEditMode };
    }),

    withMethods((state) => {
      const planProgram = inject(PlanSetProgramService);
      const setStore = inject(storeToken);
      const logger = inject(AppLogger);

      const closeView = () => {
        planProgram.cancleAddOrEdit();
      };

      const patchSet = (partialSet: Partial<TSet>) => {
        patchState(state, (store) => ({
          ...store,
          set: {
            ...store.set,
            ...partialSet,
          },
        }));
      };

      const editSet = rxMethod<string>(
        pipe(
          switchMap((setId: string) =>
            concat(
              interval(200).pipe(
                takeWhile(() => {
                  const storeSet = setStore.getSet(setId)();
                  return storeSet === undefined;
                }),
                takeUntil(timer(5000))
              ),
              of(setId).pipe(
                filter(
                  (setId) =>
                    state.set()?.id !== setId &&
                    setStore.getSet(setId)() !== undefined
                ),
                tap((setId: string) => {
                  logger.debug(
                    `WithEditSetFeature: Editing set with id: ${setId}`
                  );
                }),
                tap((setId) => {
                  const storeSet = setStore.getSet(setId)();
                  patchState(state, (store) => ({
                    ...store,
                    set: storeSet,
                  }));
                }),
                take(1)
              ),
              of(setId).pipe(
                filter((setId) => setStore.getSet(setId)() === undefined),
                tap(() => {
                  logger.warning(
                    `WithEditSetFeature: Set with id: ${setId} not found for ${JSON.stringify(
                      state.urlNameForProgramEdit()
                    )}`
                  );

                  closeView();
                }),
                take(1)
              )
            )
          )
        )
      );

      const setAddNewContentState = () => {
        const newSet = state.defaultSet();
        patchState(state, (store) => ({
          ...store,
          set: newSet,
        }));
      };

      const setStateForNewAction = rxMethod<string>(
        pipe(
          filter((url) => {
            if (!url.startsWith('/plan/program/')) {
              return false;
            }

            const containsUrl = state
              .urlNameForProgramEdit()
              .some((urlPart) => url.includes(urlPart));

            return containsUrl;
          }),
          tap((url: string) => {
            if (state.isEditMode()) {
              const setId = url.split('/').pop();
              editSet(setId);
            } else {
              setAddNewContentState();
            }
          })
        )
      );

      return { closeView, patchSet, setStateForNewAction };
    }),
    withHooks((state, routingStore = inject(RoutingStore)) => ({
      onInit() {
        state.setStateForNewAction(routingStore.currentUrl);
      },
    }))
  );
}
