import { ProgramSetStore } from 'src/app/infrastructure/models/interfaces/program-set-store';
import { KnowledgeSet } from '../models/knowledge-set';
import { KnowledgeSetDTO } from '../models/knowledge-set-dto';
import { InjectionToken, inject } from '@angular/core';
import { signalStore, withMethods, withHooks } from '@ngrx/signals';
import { AppLogger } from 'src/app/infrastructure/services/logging/app-logger.service';
import { withProgramSets } from 'src/app/infrastructure/store-features/with-program-sets.feature';
import { mapKnowledgeSetDtoToKnowledgeSet } from '../services/knowledge-program.mapper';
import { KnowledgeSetApiToken } from '../services/knowledge-set-api.service';
import { generateProgramSetCrudMessages } from '../../set-programs/services/program-set-crud-messages';

export const KnowledgeSetStoreToken = new InjectionToken<
  ProgramSetStore<KnowledgeSet, KnowledgeSetDTO>
>('KnowledgeSetStore');

export const KnowledgeSetStore = signalStore(
  {
    providedIn: 'root',
  },
  withMethods(() => ({
    mapEntity: (dto: KnowledgeSetDTO) => mapKnowledgeSetDtoToKnowledgeSet(dto),
  })),
  withProgramSets<KnowledgeSet, KnowledgeSetDTO>(
    KnowledgeSetApiToken,
    generateProgramSetCrudMessages('set')
  ),
  withHooks((_, logger = inject(AppLogger)) => ({
    onInit: () => {
      logger.debug('KnowledgeSetStore initialized');
    },
  }))
);
