import {
  patchState,
  signalStoreFeature,
  type,
  withComputed,
  withMethods,
  withState,
} from '@ngrx/signals';
import { computed, inject, Signal } from '@angular/core';
import { rxMethod } from '@ngrx/signals/rxjs-interop';
import { HttpErrorResponse } from '@angular/common/http';
import { tapResponse } from '@ngrx/component-store';
import { pipe, tap, switchMap } from 'rxjs';
import { NumbersProgramDto } from '../models/numbers-program.dto';
import { NumbersProgram } from '../models/numbers-program';
import { NumbersProgramApiService } from '../services/numbers-program-api.service';

type NumbersProgramState = {
  isUpdatingCurrentProgram: boolean;
  errorMessageForCurrentProgramUpdate: string;
};

const initialState: NumbersProgramState = {
  isUpdatingCurrentProgram: false,
  errorMessageForCurrentProgramUpdate: '',
};

export function withNumbersProgramFeatures() {
  return signalStoreFeature(
    {
      methods: type<{
        currentProgram: Signal<NumbersProgram>;
        setProgram: (program: NumbersProgramDto) => void;
      }>(),
    },
    withState(initialState),
    withComputed((state) => ({
      haveErrorUpdatingCurrentProgram: computed(() => {
        return state.errorMessageForCurrentProgramUpdate() !== '';
      }),
    })),
    withMethods((store, api = inject(NumbersProgramApiService)) => {
      const changeHighNumber = rxMethod<{
        programId: string;
        programType: number;
        newHigh: number;
      }>(
        pipe(
          tap(() =>
            patchState(store, () => ({
              isUpdatingCurrentProgram: true,
              errorMessageForCurrentProgramUpdate: '',
            }))
          ),
          switchMap((data) =>
            api
              .patchHighestNumber(
                data.programId,
                data.programType,
                data.newHigh
              )
              .pipe(
                tapResponse(
                  (dto) => {
                    patchState(store, () => ({
                      isUpdatingCurrentProgram: false,
                      errorMessageForCurrentProgramUpdate: '',
                    }));

                    store.setProgram(dto);
                  },
                  (error: HttpErrorResponse) => {
                    patchState(store, () => ({
                      errorMessageForCurrentProgramUpdate: error.message,
                      isUpdatingCurrentProgram: false,
                    }));
                  }
                )
              )
          )
        )
      );

      return { changeHighNumber };
    })
  );
}
