import { mergeMap, Observable, pipe, tap, UnaryFunction } from 'rxjs';
import { AppLogger } from 'src/app/infrastructure/services/logging/app-logger.service';
import { ProcessState } from '../models/entity-process-state';
import { Entity } from '../models/entity';
import { patchState, StateSignal } from '@ngrx/signals';
import { tapResponse } from '@ngrx/operators';
import { HttpErrorResponse } from '@angular/common/http';

export function makeApiCall<TEntity extends Entity, TDto>(
  state: StateSignal<object>,
  logger: AppLogger,
  processList: ProcessState<TEntity>[],
  createNewState: (updatedList: ProcessState<TEntity>[]) => {
    [key: string]: unknown;
  },
  apiCall: (entity: TEntity) => Observable<TDto>,
  mapEntity: (dto: TDto) => TEntity,
  updateState: (input: TEntity, apiResult: TEntity) => void,
): UnaryFunction<Observable<TEntity>, Observable<TEntity>>;

export function makeApiCall<TEntity extends Entity, TDto>(
  state: StateSignal<object>,
  logger: AppLogger,
  processList: ProcessState<TEntity>[],
  createNewState: (updatedList: ProcessState<TEntity>[]) => {
    [key: string]: unknown;
  },
  apiCall: (entity: TEntity) => Observable<TDto>,
  mapEntity: (dto: TDto) => TEntity,
  updateState: (input: TEntity, apiResult: TEntity) => void,
  createNewProcess: (entity: TEntity) => ProcessState<TEntity>
): UnaryFunction<Observable<TEntity>, Observable<TEntity>>;

export function makeApiCall<TEntity extends Entity, TDto>(
  state: StateSignal<object>,
  logger: AppLogger,
  processList: ProcessState<TEntity>[],
  createNewState: (updatedList: ProcessState<TEntity>[]) => {
    [key: string]: unknown;
  },
  apiCall: (entity: TEntity) => Observable<TDto>,
  mapEntity: (dto: TDto) => TEntity,
  updateState: (input: TEntity, apiResult: TEntity) => void,
  createNewProcess: (entity: TEntity) => ProcessState<TEntity> | null = null
) {
  return pipe(
    tap((entity: TEntity) => {
      logger.debug('Start data work with to api');

      let newProcess: ProcessState<TEntity>;

      if (createNewProcess === null) {
        newProcess = {
          entity: entity,
          status: 'processing',
          error: undefined,
        };
      } else {
        newProcess = createNewProcess(entity);
      }

      const stateUpdate = createNewState([...processList, newProcess]);

      patchState(state, (store) => ({
        ...store,
        ...stateUpdate,
      }));
    }),
    mergeMap((entity) => {
      return apiCall(entity).pipe(
        tapResponse(
          (dto) => {
            const newEntity = mapEntity(dto);
            updateState(entity, newEntity);

            const newIsProcessing = processList.filter(
              (x) => x.entity !== entity
            );

            const stateUpdate = createNewState([...newIsProcessing]);

            patchState(state, (store) => ({
              ...store,
              ...stateUpdate,
            }));
          },
          (error: HttpErrorResponse) => {
            logger.warning('Error processing call', error);

            const indexUpdate = processList.findIndex(
              (x) => x.entity.id === entity.id
            );

            const newIsProcessing = [...processList];
            newIsProcessing[indexUpdate] = {
              entity: entity,
              status: 'error',
              error: error.message,
            };

            const stateUpdate = createNewState([...newIsProcessing]);

            patchState(state, (store) => ({
              ...store,
              ...stateUpdate,
            }));
          }
        )
      );
    })
  );
}
