import { DisplaySetInList } from 'src/app/features/plan-set-program/models/display-set-in-list';
import { ReadingCategory } from '../models/reading-category';
import { PlanSpecificSetProgramService } from '../../set-programs/services/plan-specific-set-program.service';
import { ReadingCategoryDTO } from '../models/reading-category-dto';
import { ReadingProgram } from '../../reading-program/models/reading-program';
import { ProgramSetStore } from 'src/app/infrastructure/models/interfaces/program-set-store';
import { ProgramSettings } from 'src/app/infrastructure/models/program-settings';
import { ProgramStore } from 'src/app/infrastructure/models/interfaces/program-store';

export abstract class PlanReadingCategoryProgramService extends PlanSpecificSetProgramService<
  ReadingCategory,
  ReadingCategoryDTO,
  ReadingProgram
> {
  public readonly setNames = 'categories';
  public readonly addSetUrl = 'add-category';
  public readonly editSetUrl = 'edit-category';
  public readonly canCopy = true;

  constructor(
    store: ProgramSetStore<ReadingCategory, ReadingCategoryDTO>,
    programStore: ProgramStore<ReadingProgram>,
    settings: ProgramSettings[]
  ) {
    super(store, programStore, settings);
  }

  public getSetAsDisplay(set: ReadingCategory): DisplaySetInList {
    const display: DisplaySetInList = {
      setId: set.id,
      title: set.title,
      content: set.content.map((x) => x.textOnCard),
    };

    return display;
  }

  public getNbrOfContentOnPrintedPage(): number {
    return this.programStore.currentProgram().nbrOfContentOnPrintPage || 1;
  }

  public getContentToPrint(setId: string): string[] {
    const set = this.getSetById(setId);
    return set.content.map((x) => x.textOnCard);
  }
}
