import {
  signalStoreFeature,
  type,
  withMethods,
} from '@ngrx/signals';
import { EntityState } from '@ngrx/signals/entities';
import { inject } from '@angular/core';
import { map, Observable } from 'rxjs';
import { Entity } from '../models/entity';
import { DataLoadApiToken } from '../models/interfaces/data-load-api';
import { withDataLoad } from './with-data-load.feature';


export function withDataLoadAll<TEntity extends Entity, TDto>(
  apiToken: typeof DataLoadApiToken
) {
  return signalStoreFeature(
    {
      state: type<EntityState<TEntity>>(),
      methods: type<{
        mapDto: (dto: TDto) => TEntity;
      }>(),
    },
    withMethods((_, api = inject(apiToken)) => ({
      loadData(): Observable<TDto[]> {
        return api.loadData().pipe(
          map((dtos) => dtos.map((dto) => dto as TDto))
        );
      }
    })),
    withDataLoad<TEntity, TDto>()
  );
}
