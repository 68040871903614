import { PlanSetProgramProviders } from "src/app/features/plan-set-program/plan-set-program.providers";
import { WordStatusProviders } from "src/app/features/word-status/word-status.providers";
import { GenericProgramProviders } from "src/app/programs/generic-program/generc-program.providers";
import { KnowledgeProgramProviders } from "src/app/programs/knowledge-bit-program/knowledge-program.providers";
import { AddtionProgramProviders } from "src/app/programs/math-addition-program/addition-program.providers";
import { EquationProgramProviders } from "src/app/programs/math-equations-program/equation-program.providers";
import { NumbersProgramProviders } from "src/app/programs/math-numbers-program/numbers-program.providers";
import { ReadingCategoryProgramProviders } from "src/app/programs/reading-category-program/reading-category-program.providers";
import { HomemadeBooksProgramProviders } from "src/app/programs/reading-homemade-books-program/homemade-books-program.providers";

export const ProgramProviders = [
  ...ReadingCategoryProgramProviders,
  ...HomemadeBooksProgramProviders,
  ...NumbersProgramProviders,
  ...AddtionProgramProviders,
  ...KnowledgeProgramProviders,
  ...GenericProgramProviders,
  ...EquationProgramProviders,
]

export const FeatureProviders = [
  ...PlanSetProgramProviders,
  ...WordStatusProviders,
];
