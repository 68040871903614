import { inject } from '@angular/core';
import { signalStore, withComputed } from '@ngrx/signals';
import { Store } from '@ngrx/store';
import * as fromRouterStore from './../core/selectors/router.selectors';

export const RoutingStore = signalStore(
  { providedIn: 'root' },
  withComputed(
    (state, globalStore = inject(Store)) => ({
      params: globalStore.selectSignal(fromRouterStore.selectRouteParams),
      currentUrl: globalStore.selectSignal(fromRouterStore.selectUrl),
      queryParams: globalStore.selectSignal(fromRouterStore.selectQueryParams),
      currentRoute: globalStore.selectSignal(fromRouterStore.selectCurrentRoute),
      routeData: globalStore.selectSignal(fromRouterStore.selectRouteData),
      fragment: globalStore.selectSignal(fromRouterStore.selectFragment)
    })
  ),
);
