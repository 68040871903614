import { PlanSpecificSetProgramService } from '../../set-programs/services/plan-specific-set-program.service';
import { EquationProgram } from '../models/equation-program';
import { EquationSet } from '../models/equation-set';
import { EquationSetDto } from '../models/equation-set.dto';
import { DisplaySetInList } from 'src/app/features/plan-set-program/models/display-set-in-list';
import { ProgramSetStore } from 'src/app/infrastructure/models/interfaces/program-set-store';
import { ProgramSettings } from 'src/app/infrastructure/models/program-settings';
import { ProgramStore } from 'src/app/infrastructure/models/interfaces/program-store';


export class PlanEquationProgramService extends PlanSpecificSetProgramService<
  EquationSet,
  EquationSetDto,
  EquationProgram
> {
  public readonly setNames = 'sets';
  public readonly addSetUrl = 'add-set';
  public readonly editSetUrl = 'edit-set';
  public readonly canCopy = false;
  public readonly allowsEdit = false;

  constructor(
    programSetStore: ProgramSetStore<EquationSet, EquationSetDto>,
    programStore: ProgramStore<EquationProgram>,
    settings: ProgramSettings[]
  ) {
    super(programSetStore, programStore, settings);
  }

  public getSetAsDisplay(set: EquationSet): DisplaySetInList {
    const toDisplay: DisplaySetInList = {
      setId: set.id,
      title: null,
      content: set.equations
    };

    return toDisplay;
  }

  public getNbrOfContentOnPrintedPage(): number {
    return 1;
  }

  public getContentToPrint(setId: string): string[] {
    const set = this.getSetById(setId);

    return set.equations;
  }
}
