import { Directive, effect, TemplateRef, ViewContainerRef } from '@angular/core';
import { AuthenticationService } from 'src/app/infrastructure/services/auth/authentication.service';

@Directive({
  selector: '[appRequrireAuthentication]',
  standalone: true
})
export class RequrireAuthenticationDirective {

  constructor(
    private templateRef: TemplateRef<unknown>,
    private viewContainerRef: ViewContainerRef,
    private authService: AuthenticationService,
  ) {}

  private isAuthenticatedEffect = effect(() => {
    const isAuthenticated = this.authService.userIsAuthenticated();

    if (isAuthenticated) {
      this.createView();
    } else {
      this.removeView();
    }
  })

  private removeView(): void {
    this.viewContainerRef.clear();
  }

  private createView(): void {
    this.viewContainerRef.createEmbeddedView(this.templateRef);
  }
}
