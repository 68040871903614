import { computed, inject, Inject, Injectable, Signal } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import {
  MsalBroadcastService,
  MsalGuardConfiguration,
  MsalService,
  MSAL_GUARD_CONFIG,
} from '@azure/msal-angular';
import {
  RedirectRequest,
  EventMessage,
  InteractionStatus,
  EventType,
} from '@azure/msal-browser';
import { Observable } from 'rxjs';
import {
  distinctUntilChanged,
  filter,
  map,
  shareReplay,
  switchMap,
  tap,
} from 'rxjs/operators';
import { AppConfig } from 'src/app/core/configuration/app-config';
import { AppLogger } from '../logging/app-logger.service';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  private logger = inject(AppLogger).forContext('AuthenticationService');

  private authChanged = toSignal(
    this.msalBroadcastService.msalSubject$.pipe(
      switchMap(() => this.msalBroadcastService.inProgress$),
      filter((status) => status === InteractionStatus.None),
      distinctUntilChanged(),
      tap(() => {
        this.logger.debug(`Auth state changed`);
      }),
      shareReplay(1)
    )
  );

  public userLoggedOut = toSignal(
    this.msalBroadcastService.msalSubject$.pipe(
      filter(
        (event: EventMessage) =>
          event.eventType === EventType.LOGOUT_SUCCESS ||
          event.eventType === EventType.ACQUIRE_TOKEN_FAILURE
      ),
      tap(() => {
        localStorage.clear();
        this.logger.debug('User logged out');
      }),
      shareReplay(1)
    )
  );

  constructor(
    private appConfig: AppConfig,
    private msalService: MsalService,
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private msalBroadcastService: MsalBroadcastService,
  ) {}

  public get isInProcess(): Observable<boolean> {
    return this.msalBroadcastService.inProgress$.pipe(
      map((x: InteractionStatus) => x !== InteractionStatus.None)
    );
  }

  public login(): Observable<void> {
    if (this.msalGuardConfig.authRequest) {
      return this.msalService.loginRedirect({
        ...this.msalGuardConfig.authRequest,
      } as RedirectRequest);
    } else {
      return this.msalService.loginRedirect();
    }
  }

  public authError(): Observable<EventMessage> {
    return this.msalBroadcastService.msalSubject$.pipe(
      filter((result) => {
        return (
          result.eventType === EventType.LOGIN_FAILURE ||
          result.eventType === EventType.LOGOUT_FAILURE ||
          result.eventType === EventType.ACQUIRE_TOKEN_FAILURE ||
          result.eventType === EventType.SSO_SILENT_FAILURE
        );
      })
    );
  }

  public authSuccess(): Observable<EventMessage> {
    return this.msalBroadcastService.msalSubject$.pipe(
      filter(
        (result) =>
          result.eventType === EventType.LOGIN_SUCCESS ||
          result.eventType === EventType.LOGOUT_SUCCESS ||
          result.eventType === EventType.ACQUIRE_TOKEN_SUCCESS ||
          result.eventType === EventType.SSO_SILENT_SUCCESS
      )
    );
  }

  public logout(): void {
    this.msalService.logoutRedirect();
  }

  public register() {
    const registerUserFlowRequest: RedirectRequest = {
      authority: this.appConfig.authentication.authorities.register,
      scopes: [],
    };

    this.msalService.loginRedirect(registerUserFlowRequest);
  }

  public edit() {
    const editProfileFlowRequest: RedirectRequest = {
      authority: this.appConfig.authentication.authorities.editProfile,
      scopes: [],
    };

    this.msalService.loginRedirect(editProfileFlowRequest);
  }

  public userIsAuthenticated = computed(() => {
    this.authChanged();

    return this.msalService.instance.getAllAccounts().length > 0;
  });

  public userId = computed(() => {
    if (this.userIsAuthenticated()) {
      const userId =
        this.msalService.instance.getAllAccounts()[0].localAccountId;
      return userId;
    }

    return undefined;
  });

  public userRole: Signal<'User' | 'Admin' | undefined> = computed(() => {
    const claims = this.userClaims();

    const role: string | undefined =
      (claims.extension_Role as string) || undefined;

    if (role === 'Admin' || role === 'User') {
      return role;
    } else if (role !== undefined) {
      this.logger.warning('Unknown role: ' + role);
      return undefined;
    }

    return undefined;
  });

  private userClaims = computed(() => {
    this.authChanged();

    const claims = this.msalService.instance.getAllAccounts()[0].idTokenClaims;

    return claims;
  });
}
