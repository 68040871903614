import {
  APP_INITIALIZER,
  EnvironmentInjector,
  ErrorHandler,
  NgModule,
} from '@angular/core';
import {
  MSALGuardConfigFactory,
  MSALInstanceFactory,
  MSALInterceptorConfigFactory,
} from './configuration/msal-configratuion';
import {
  MsalBroadcastService,
  MsalGuard,
  MsalInterceptor,
  MsalModule,
  MsalService,
  MSAL_GUARD_CONFIG,
  MSAL_INSTANCE,
  MSAL_INTERCEPTOR_CONFIG,
} from '@azure/msal-angular';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { provideStore } from '@ngrx/store';
import { environment } from 'src/environments/environment';
import { provideRouterStore, routerReducer } from '@ngrx/router-store';
import { DefaultApiEndpointInterceptor } from './interceptors/default-api-endpoint.interceptor';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ErrorIntercept } from './interceptors/error.interceptor';
import { ApplicationinsightsAngularpluginErrorService } from '@microsoft/applicationinsights-angularplugin-js';
import { AppConfig, APP_CONFIG } from './configuration/app-config';
import { LoggerFactory } from './configuration/logger-config';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {
  FeatureProviders,
  ProgramProviders as ProgramProviders,
} from './configuration/providers-config';
import { ChildProviders } from '../features/children/children.providers';
import { AllProgramsProviders } from '../programs/all-programs/program.providers';
import { AppLogger } from '../infrastructure/services/logging/app-logger.service';
import { ConsoleLogger } from '../infrastructure/services/logging/console-logger';
import { InsightsService } from '../infrastructure/services/logging/insights.service';
import { ApplicationInsightsConfig } from './configuration/application-insights.config';
import {
  LanguageApiService,
  LanguageApiToken,
} from '../features/languages/services/language-api.service';
import { AuthenticationService } from '../infrastructure/services/auth/authentication.service';

@NgModule({
  declarations: [],
  imports: [
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    MsalModule,
  ],
  providers: [
    provideStore({
      router: routerReducer,
    }),
    provideRouterStore(),
    {
      provide: HTTP_INTERCEPTORS,
      useClass: DefaultApiEndpointInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorIntercept,
      multi: true,
    },
    {
      provide: MSAL_INSTANCE,
      useFactory: (config: AppConfig) => MSALInstanceFactory(config),
      deps: [APP_CONFIG],
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useFactory: (config: AppConfig) => MSALGuardConfigFactory(config),
      deps: [APP_CONFIG],
    },
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory: (config: AppConfig) => MSALInterceptorConfigFactory(config),
      deps: [APP_CONFIG],
    },
    {
      provide: APP_INITIALIZER,
      useFactory:
        (
          appConfig: AppConfig,
          insight: InsightsService,
          authService: AuthenticationService,
          injector: EnvironmentInjector
        ) =>
        () => {
          if(appConfig.isProduction){
          return new ApplicationInsightsConfig(insight, authService, injector);
          }
        },
      multi: true,
      deps: [
        AppConfig,
        InsightsService,
        AuthenticationService,
        EnvironmentInjector,
      ],
    },
    {
      provide: AppConfig,
      useFactory: (config: AppConfig) => config,
      deps: [APP_CONFIG],
    },
    InsightsService,
    ConsoleLogger,
    {
      provide: ErrorHandler,
      useFactory: (
        appInsights: ApplicationinsightsAngularpluginErrorService
      ) => {
        if (environment.production) {
          return appInsights;
        } else {
          return new ErrorHandler();
        }
      },
      deps: [ApplicationinsightsAngularpluginErrorService],
    },
    {
      provide: AppLogger,
      useFactory: (
        insights: InsightsService,
        console: ConsoleLogger,
        config: AppConfig
      ) => LoggerFactory(config, console, insights),
      deps: [InsightsService, ConsoleLogger, AppConfig],
    },
    MsalService,
    MsalGuard,
    MsalBroadcastService,
    ...ChildProviders,
    ...AllProgramsProviders,
    ...ProgramProviders,
    ...FeatureProviders,
    {
      provide: LanguageApiToken,
      useExisting: LanguageApiService,
    },
  ],
})
export class CoreModule {
  constructor(private insights: InsightsService) {
    if (environment.production) {
      this.insights.init();
    }
  }
}
