import { Routes } from '@angular/router';

export const userAccountRoutes: Routes = [
  {
    path: 'login',
    loadComponent: () =>
      import('./containers/login/login.component').then(
        (mod) => mod.LoginComponent
      ),
  },
  {
    path: 'logout',
    loadComponent: () =>
      import('./containers/logout/logout.component').then(
        (mod) => mod.LogoutComponent
      ),
  },
  {
    path: 'register',
    loadComponent: () =>
      import('./containers/register/register.component').then(
        (mod) => mod.RegisterComponent
      ),
  },
  {
    path: 'loading',
    loadComponent: () =>  import('./containers/load-account/load-account.component').then(
      (mod) => mod.LoadAccountComponent
    ),
    data: {
      hideHeader: true,
    }
  },
  {
    path: '',
    loadComponent: () =>
      import('./containers/login/login.component').then(
        (mod) => mod.LoginComponent
      ),
  },
];
