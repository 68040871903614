import { Routes } from '@angular/router';
import {
  addProgramRoutes,
  editProgramRoutes,
} from 'src/app/core/configuration/app-route-config';
import { EditProgramService } from 'src/app/programs/all-programs/services/edit-program.service';
import { ManageProgramSettingsService } from 'src/app/programs/all-programs/services/manage-program-settings.service';

const addProgramWithChildrenLoadComponent = (routes: Routes): Routes => {
  const childrenRoutes = routes.map((route) => {
    if (route.path) {
      return {
        ...route,
        path: `${route.path}/:childrenIdentifier`,
      };
    } else {
      throw new Error('Route must have a path');
    }
  });

  return childrenRoutes;
};

export const SettingsRoutes: Routes = [
  {
    path: 'programs/:childrenIdentifier',
    loadComponent: () =>
      import(
        './features/edit-program/containers/program-settings-overview-page/program-settings-overview.component'
      ).then((m) => m.ProgramSettingsOverviewComponent),
    children: [...editProgramRoutes],
    providers: [
      {
        provide: ManageProgramSettingsService,
        useExisting: EditProgramService
      },
    ],
  },
  {
    path: 'add-program',
    loadComponent: () =>
      import('./containers/settings-page/settings.component').then(
        (m) => m.SettingsComponent
      ),
    children: [
      ...addProgramWithChildrenLoadComponent(addProgramRoutes),
      ...addProgramRoutes,
    ],
  },
  {
    path: '**',
    loadComponent: () =>
      import('./containers/settings-page/settings.component').then(
        (m) => m.SettingsComponent
      ),
  },
];
