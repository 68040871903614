import { inject, InjectionToken } from '@angular/core';
import { signalStoreFeature, withMethods } from '@ngrx/signals';
import { ChildStore } from 'src/app/features/children/children.store';
import { withSpecificProgram } from 'src/app/infrastructure/store-features/with-specific-program.feature';
import {
  mapEquationProgramDtoToEquationProgram,
  mapEquationProgramToDot,
} from '../helpers/equation-program.mapper';
import { EquationProgram } from '../models/equation-program';
import { EquationProgramDto } from '../models/equation-program.dto';
import { CrudApi } from 'src/app/infrastructure/models/interfaces/api';
import { CrudMessages } from 'src/app/infrastructure/models/crud-messages';
import { ProgramSettings } from 'src/app/infrastructure/models/program-settings';

export function withEquationProgramFeature(
  apiToken: InjectionToken<CrudApi<EquationProgram, EquationProgramDto>>,
  settings: ProgramSettings[],
  crudMessages: CrudMessages
) {
  return signalStoreFeature(
    withMethods((_, childStore = inject(ChildStore)) => ({
      mapEntity: (dto: EquationProgramDto) =>
        mapEquationProgramDtoToEquationProgram(dto, childStore.entities()),
      mapEntityToDto: (program: EquationProgram) =>
        mapEquationProgramToDot(program),
    })),
    withSpecificProgram<EquationProgram, EquationProgramDto>(
      apiToken,
      settings,
      crudMessages
    )
  );
}
