import {
  Directive,
  effect,
  TemplateRef,
  ViewContainerRef,
} from '@angular/core';
import { AuthenticationService } from 'src/app/infrastructure/services/auth/authentication.service';

@Directive({
  selector: '[appNotWhenAuthenticated]',
  standalone: true,
})
export class NotWhenAuthenticatedDirective  {

  constructor(
    private templateRef: TemplateRef<unknown>,
    private viewContainerRef: ViewContainerRef,
    private authService: AuthenticationService
  ) {}

  private isAuthenticatedEffect = effect(() => {
    const isAuthenticated = this.authService.userIsAuthenticated();

    if (isAuthenticated) {
      this.removeView();
    } else {
      this.createView();
    }
  })

  private removeView(): void {
    this.viewContainerRef.clear();
  }

  private createView(): void {
    this.viewContainerRef.createEmbeddedView(this.templateRef);
  }
}
