import { InjectionToken, inject } from "@angular/core";
import { signalStore, withMethods } from "@ngrx/signals";
import { ChildStore } from "src/app/features/children/children.store";
import { withSpecificProgram } from "src/app/infrastructure/store-features/with-specific-program.feature";

import { GenericProgramSettings } from "../generic-program-settings";
import { GenericProgram } from "../models/generic-program";
import { GenericProgramDto } from "../models/generic-program-dto";
import { GenericProgramApiToken } from "../services/generic-program-api.service";
import { mapGenericProgramDtoToProgram, mapGenericProgramToDto } from "../services/generic-program.mapper";
import { ProgramStore } from "src/app/infrastructure/models/interfaces/program-store";
import { CrudMessages } from "src/app/infrastructure/models/crud-messages";

export const GenericProgramStoreToken = new InjectionToken<
  ProgramStore<GenericProgram>
>('NameProgramStore');

const GenericProgramCrudMessages: CrudMessages = {
  addUserError: {
    heading: 'Failed to add program',
    message: 'Some data on the new program was invalid, and the program could not be added. Please try again.',
  },
  addServerFail: {
    heading: 'Failed to add program',
    message: 'We did something wrong and could not save your new program. Please try again later.',
  },

  editUserError: {
    heading: 'Failed to edit program',
    message: 'Some data on the program was invalid, and the program could not be edited. Please try again.',
  },
  editServerFail: {
    heading: 'Failed to edit program',
    message: 'We did something wrong and could not save your changes. Please try again later.',
  },

  removeUserError: {
    heading: 'Failed to remove program',
    message: 'We could not remove the program. Please try again.',
  },
  removeServerFail: {
    heading: 'Failed to remove program',
    message: 'We did something wrong and could not remove the program. Please try again later.',
  },
}

export const GenericProgramStore = signalStore(
  {
    providedIn: 'root',
  },
  withMethods((_, childStore = inject(ChildStore)) => ({
    mapEntity: (dto: GenericProgramDto) =>
      mapGenericProgramDtoToProgram(dto, childStore.entities()),
    mapEntityToDto: (program: GenericProgram) =>
      mapGenericProgramToDto(program),
  })),
  withSpecificProgram<GenericProgram, GenericProgramDto>(
    GenericProgramApiToken,
    [GenericProgramSettings],
    GenericProgramCrudMessages
  )
);
