import { inject, InjectionToken } from '@angular/core';
import { signalStore, withMethods } from '@ngrx/signals';
import { ChildStore } from 'src/app/features/children/children.store';
import { HomemadeBooksProgramDto } from '../models/homemade-books-program-dto';
import {
  mapHomemadeBookProgramDtoToProgram,
  mapHomemadeBookProgramToProgramDto,
} from '../services/homemade-books-mapper';
import { HomemadeBookProgram } from '../models/homemade-book-program';
import { withSpecificProgram } from 'src/app/infrastructure/store-features/with-specific-program.feature';
import { HomemadeBooksProgramSettings } from '../homemade-books-program.settings';
import { HomemadeBooksProgramApiToken } from '../services/homemade-books-program-api.service';
import { ProgramStore } from 'src/app/infrastructure/models/interfaces/program-store';
import { CrudMessages } from 'src/app/infrastructure/models/crud-messages';

export const HomemadeBookProgramStoreToken = new InjectionToken<
  ProgramStore<HomemadeBookProgram>
>('HomemadeBookProgramStore');

const HomemadeBooksCrudMessages: CrudMessages = {
  addUserError: {
    heading: 'Failed to add homemade book program',
    message: 'Some data on the new program was invalid, and the program could not be added. Please try again.',
  },
  addServerFail: {
    heading: 'Failed to add homemade book program',
    message: 'We did something wrong and could not save your new program. Please try again later.',
  },

  editUserError: {
    heading: 'Failed to edit homemade book program',
    message: 'Some data on the program was invalid, and the program could not be edited. Please try again.',
  },
  editServerFail: {
    heading: 'Failed to edit homemade book program',
    message: 'We did something wrong and could not save your changes. Please try again later.',
  },

  removeUserError: {
    heading: 'Failed to remove homemade book program',
    message: 'We could not remove the program. Please try again.',
  },
  removeServerFail: {
    heading: 'Failed to remove homemade book program',
    message: 'We did something wrong and could not remove the program. Please try again later.',
  },
}

export const HomemadeBookProgramStore = signalStore(
  {
    providedIn: 'root',
  },
  withMethods((_, childStore = inject(ChildStore)) => ({
    mapEntity: (dto: HomemadeBooksProgramDto) =>
      mapHomemadeBookProgramDtoToProgram(dto, childStore.entities()),
    mapEntityToDto: (program: HomemadeBookProgram) =>
      mapHomemadeBookProgramToProgramDto(program),
  })),
  withSpecificProgram<HomemadeBookProgram, HomemadeBooksProgramDto>(
    HomemadeBooksProgramApiToken,
    [HomemadeBooksProgramSettings],
    HomemadeBooksCrudMessages
  )
);

