import {
  patchState,
  signalStoreFeature,
  type,
  withComputed,
  withMethods,
  withState,
} from '@ngrx/signals';
import { addEntities, EntityState } from '@ngrx/signals/entities';
import { computed, inject } from '@angular/core';
import { rxMethod } from '@ngrx/signals/rxjs-interop';
import { exhaustMap, filter, Observable, pipe, tap } from 'rxjs';
import { tapResponse } from '@ngrx/component-store';
import { HttpErrorResponse } from '@angular/common/http';
import { AppLogger } from 'src/app/infrastructure/services/logging/app-logger.service';
import { Entity } from '../models/entity';

export type DataLoadState = {
  isLoading: boolean;
  haveLoaded: boolean;
  loadingError: string | undefined;
};

const initialState: DataLoadState = {
  isLoading: false,
  haveLoaded: false,
  loadingError: undefined,
};

export function withDataLoad<TEntity extends Entity, TDto>() {
  return signalStoreFeature(
    {
      state: type<EntityState<TEntity>>(),
      methods: type<{
        loadData: () => Observable<TDto[]>,
        mapDto: (dto: TDto) => TEntity;
      }>(),
    },
    withState(initialState),
    withComputed((state) => ({
      haveDataLoadingError: computed(() => {
        return !state.isLoading() && state.loadingError() !== undefined;
      }),
    })),
    withMethods(
      (state, logger = inject(AppLogger)) => ({
        loadData: rxMethod<boolean>(
          pipe(
            filter(
              (forceLoad) =>
                (!state.isLoading() && !state.haveLoaded()) || forceLoad
            ),
            tap(() => {
              patchState(state, (store) => ({
                ...store,
                isLoading: true,
                loadingError: undefined,
              }));
            }),
            exhaustMap(() =>
              state.loadData().pipe(
                tapResponse(
                  (dtos) => {
                    patchState(state, (store) => ({
                      ...store,
                      isLoading: false,
                      haveLoaded: true,
                    }));

                    const entities = dtos.map((dto) =>
                      state.mapDto(dto as TDto)
                    );

                    patchState(state, addEntities(entities));
                  },

                  (error: HttpErrorResponse) => {
                    patchState(state, (store) => ({
                      ...store,
                      isLoading: false,
                      loadingError: error.message,
                    }));

                    logger.error('Failed to load data', error);
                  }
                )
              )
            )
          )
        ),
      })
    )
  );
}
