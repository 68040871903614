import {
  signalStoreFeature,
  type,
  withComputed,
  withMethods,
} from '@ngrx/signals';
import { computed, inject, InjectionToken } from '@angular/core';
import { ProgramSettings } from 'src/app/infrastructure/models/program-settings';
import { ChildStore } from 'src/app/features/children/children.store';
import { rxMethod } from '@ngrx/signals/rxjs-interop';
import { pipe, tap } from 'rxjs';
import { ProgramDto } from 'src/app/programs/all-programs/models/dtos/program-dto';
import { Program } from 'src/app/programs/all-programs/models/program';
import { ProgramStore } from 'src/app/programs/all-programs/program.store';
import { CrudApi } from '../models/interfaces/api';
import { LoadFilter } from '../models/interfaces/load-api';
import { withStatelessCrud } from './with-crud-without-state.feature';
import { CrudMessages } from '../models/crud-messages';


export function withSpecificProgram<
  TProgram extends Program,
  TDto extends ProgramDto
>(
  apiToken: InjectionToken<CrudApi<TProgram, TDto>>,
  programSettings: ProgramSettings[],
  crudMessages: CrudMessages
) {
  return signalStoreFeature(
    {
      methods: type<{
        mapEntity: (dto: TDto) => TProgram;
        mapEntityToDto: (program: TProgram) => TDto;
      }>(),
    },
    withMethods((state,  programStore = inject(ProgramStore)) => ({
      addEntityToState: (_input: TProgram, program: TProgram) =>
        programStore.addProgram(state.mapEntityToDto(program)),

      addLoadedEntitiesToState: (_filter: LoadFilter, entities: TProgram[]) => {
        programStore.addLoadedPrograms(entities.map((prog) => state.mapEntityToDto(prog)));
      },

      updateEntityInState: (_input: TProgram, program: TProgram) => {
        programStore.updateProgram(program.id, state.mapEntityToDto(program));
      },

      removeEntityFromState: (programToRemove: TProgram) => {
        programStore.deleteProgram(programToRemove.id);
      }
    })),
    withStatelessCrud<TProgram, TDto>(apiToken, crudMessages),
    withComputed((_, programStore = inject(ProgramStore)) => ({
      isLoading: programStore.isLoading,
      haveError: programStore.haveDataLoadingError,
      haveLoaded: programStore.haveLoaded,
    })),
    withMethods((state) => {
      const programStore = inject(ProgramStore);
      const childStore = inject(ChildStore);

      const programs = computed(() => {
        const programTypes = programSettings.map((prog) => prog.programType);
        const programDtos = programStore.programsOfTypes(
          programTypes
        )() as TDto[];

        return programDtos.map((dto) => state.mapEntity(dto));
      });

      const programWithId = (programId: string) =>
        computed(() => {
          const allPrograms = programs();
          return allPrograms.find((x) => x.id === programId);
        });

      const currentProgram = computed(() => {
        const allPrograms = programs();
        const currentChildren = childStore.selectCurrentChildrenUrlIdentifier();

        return allPrograms.find(
          (x) => x.childrenUrlIdentifier === currentChildren
        );
      });

      const haveCurrentProgram = computed(() => {
        return currentProgram() !== null && currentProgram() !== undefined;
      });

      const setProgram = (dto: TDto) => {
        programStore.updateProgram(dto.id, dto);
      };

      const deleteProgram = rxMethod<string>(
        pipe(
          tap((id) => {
            state.removeEntity({id: id} as TProgram);
          })
        )
      )

      return {
        addProgram: state.addEntity,
        editProgram: state.editntity,
        deleteProgram,
        setProgram,
        programs,
        programWithId,
        currentProgram,
        haveCurrentProgram,
      };
    })
  );
}
