import {
  patchState,
  signalStore,
  withComputed,
  withHooks,
  withMethods,
  withState,
} from '@ngrx/signals';
import { Child } from './models/child';
import { computed, inject } from '@angular/core';
import { ChildApiToken, ChildrenApiService } from './services/children-api.service';
import { rxMethod } from '@ngrx/signals/rxjs-interop';
import { exhaustMap, filter, from, pipe, switchMap, tap } from 'rxjs';
import { tapResponse } from '@ngrx/operators';
import { HttpErrorResponse } from '@angular/common/http';
import { ChildUrlIdentifierService } from './services/child-url-identifier.service';
import { RoutingStore } from 'src/app/infrastructure/routing.store';
import {
  addEntities,
  removeEntity,
  updateEntity,
  withEntities,
} from '@ngrx/signals/entities';
import { ChildDTO } from './models/child-dto';
import { ChildModifyAlertService } from './services/child-modify-alert.service';
import { EditChildModalService } from './services/edit-child-modal.service';
import { AppLogger } from 'src/app/infrastructure/services/logging/app-logger.service';
import { withDataLoadAll } from 'src/app/infrastructure/store-features/with-data-load-all.feature';

export type ChildrenState = {
  apiCallError: string | undefined;
};

const initialState: ChildrenState = {
  apiCallError: undefined,
};

export const ChildStore = signalStore(
  { providedIn: 'root' },
  withEntities<Child>(),
  withState(initialState),
  withComputed((state, routingStore = inject(RoutingStore)) => {

    const haveAnyChildren = computed(() => state.entities.length > 0);

    const selectCurrentChildrenUrlIdentifier = computed(() => {
      const urlIdentifier = routingStore.params()['childrenIdentifier'];
      return urlIdentifier;
    });

    const selectCurrentChildren = computed(() => {
      const currentUrlIdentifier = selectCurrentChildrenUrlIdentifier();

      if (!currentUrlIdentifier) {
        return undefined;
      }

      const childIdentifiers =
        ChildUrlIdentifierService.getIndividualChildIdentifiers(
          currentUrlIdentifier
        );

      const children = state
        .entities()
        .filter((child) =>
          childIdentifiers.some((x) => x === child.urlIdentifier)
        );
      return children;
    });

    const selectCurrentChildrenIds = computed(() =>
      selectCurrentChildren().map((x) => x.id)
    );

    return {
      haveAnyChildren,
      selectCurrentChildren,
      selectCurrentChildrenIds,
      selectCurrentChildrenUrlIdentifier,
    };
  }),
  withMethods((store) => {
    const api = inject(ChildrenApiService);
    const alert = inject(ChildModifyAlertService);
    const editModalService = inject(EditChildModalService);
    const logger = inject(AppLogger);

    const mapChild = (dto: ChildDTO, allChildren: ChildDTO[] | Child[]) => {
      const child: Child = {
        id: dto.apiId,
        firstName: dto.firstName,
        lastName: dto.lastName,
        urlIdentifier:
          ChildUrlIdentifierService.constructChildIdentifierForChild(
            dto,
            allChildren
          ),
      };
      return child;
    };

    const mapChildDto = (dto: ChildDTO) => {
      return mapChild(dto, store.entities());
    }

    const add = rxMethod<Child>(
      pipe(
        exhaustMap((child) =>
          api.addChild(child).pipe(
            tapResponse(
              (dto) => {
                const newChild: Child = mapChild(dto, store.entities());
                patchState(store, addEntities([newChild]));
              },
              (error: HttpErrorResponse) => {
                logger.warning('Failed to add child', error);
                patchState(store, (state) => ({
                  ...state,
                  apiCallError: error.message,
                }));

                if (error.status === 400) {
                  handleUserModifyFailed({child, isEdit: false});
                } else {
                  alert.showFailedSaveAlert();
                }
              }
            )
          )
        )
      )
    );

    const handleUserModifyFailed = rxMethod<{child: Child, isEdit: boolean}>(
      pipe(
        switchMap((data) =>
          from(alert.showUserErrorFailToSaveAlert()).pipe(
            filter((fixError) => {
              return fixError;
            }),
            switchMap(() =>
              from(
                editModalService.showEditChildWithError(data.child, {}, data.isEdit)
              ).pipe(
                tap((child) => {
                  if (child != null) {
                    add(child);
                  }
                })
              )
            )
          )
        )
      )
    );

    const edit = rxMethod<Child>(
      pipe(
        switchMap((child) =>
          api.editChild(child).pipe(
            tapResponse(
              (dto) => {
                const updatedChild: Child = mapChild(dto, store.entities());
                patchState(
                  store,
                  updateEntity({ id: dto.apiId, changes: updatedChild })
                );
              },
              (error: HttpErrorResponse) => {
                logger.warning('Failed to edit child', error);
                patchState(store, (state) => ({
                  ...state,
                  apiCallError: error.message,
                }));

                if (error.status === 400) {
                  handleUserModifyFailed({child, isEdit: true});
                } else {
                  alert.showFailedSaveAlert();
                }
              }
            )
          )
        )
      )
    );

    const remove = rxMethod<string>(
      pipe(
        switchMap((id) =>
          api.removeChild(id).pipe(
            tapResponse(
              () => {
                patchState(store, removeEntity(id));
              },
              (error: HttpErrorResponse) => {
                logger.warning('Failed to remove child', error);
                patchState(store, (state) => ({
                  ...state,
                  apiCallError: error.message,
                }));
                alert.showFailedToRemoveAlert();
              }
            )
          )
        )
      )
    );

    return {
      addEntity: add,
      updateEntity: edit,
      removeEntity: remove,
      mapDto: mapChildDto,
    };
  }),
  withDataLoadAll<Child, ChildDTO>(ChildApiToken),
  withComputed((state) => ({
    haveError: computed(() => {
      const apiCallError = state.apiCallError();
      const loadApiError = state.loadingError();
      const isLoading = state.isLoading();

      return (apiCallError !== undefined || loadApiError !== undefined) && !isLoading;
    }),
  })),
  withHooks((store, logger = inject(AppLogger)) => ({
    onInit() {
      logger.debug('Child store initialized');
      store.loadData(true);
    },
  }))
);
