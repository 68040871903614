import { effect, EnvironmentInjector } from '@angular/core';
import { InsightsService } from 'src/app/infrastructure/services/logging/insights.service';
import { AuthenticationService } from 'src/app/infrastructure/services/auth/authentication.service';

export class ApplicationInsightsConfig {
  private userLoggedOutEffect = effect(() => {
    this.authService.userLoggedOut();

    this.appInsights.clearAuthenticatedUserId();
    this.appInsights.event('User logged out');
  }, {
    injector: this.injectionContext
  });

  private userLoggedInEffect = effect(() => {
    const userId = this.authService.userId();

    if (userId !== undefined) {
      this.appInsights.setAuthenticatedUserId(userId);
      this.appInsights.event('User logged in');
    }
  }, {
    injector: this.injectionContext,
  });

  constructor(
    private appInsights: InsightsService,
    private authService: AuthenticationService,
    private injectionContext: EnvironmentInjector
  ) {}
}
